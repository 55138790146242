import React, { ComponentPropsWithRef, FC, forwardRef, PropsWithChildren } from 'react';
import { BasicButton } from '@components/buttons/BasicButton/BasicButton.component';
import { BasicLink } from '@components/buttons/BasicLink/BasicLink.component';
import { Text } from '@components/Text/Text.component';
import { CloseIcon } from '@icons/Close.icon';
import { useTranslation } from '@locales/useTranslation.hook';
import { PlcColor } from '@styles/colors';
import classnames from 'classnames';

export interface TagProps extends ComponentPropsWithRef<'a'> {
  onClose?: () => void;
  isPro?: boolean;
  isDarkGreen?: boolean;
}

const Tag: FC<PropsWithChildren<TagProps>> = forwardRef(
  ({ isPro, isDarkGreen = false, children, onClose, className, ...rest }, tagRef) => {
    const { t } = useTranslation(['search']);
    const closeButton = React.createRef<HTMLButtonElement>();
    const rootClasses = classnames(
      'plc-tag',
      isDarkGreen ? 'dark-green-style' : '',
      !!onClose ? 'removable' : '',
      className
    );

    const textFlavour = isPro ? 'dark' : 'light';

    const tagContent = (
      <>
        <span>{children}</span>
        {onClose && (
          <BasicButton
            ref={closeButton}
            aria-label={t('search.remove-tag', { tagName: children ? children.toString() : null })}
            name={t('common.close')}
            onClick={onClose}
            type="button"
          >
            <CloseIcon
              fillColor={isDarkGreen ? PlcColor.GREEN : ''}
              height="16"
              stroke={isDarkGreen ? PlcColor.GREEN : ''}
              width="16"
            />
          </BasicButton>
        )}
      </>
    );

    if (rest.href) {
      return (
        <BasicLink
          ref={tagRef}
          className={rootClasses}
          textProps={{ flavour: isDarkGreen ? 'meteo-dark-green' : textFlavour, variant: 'tag' }}
          {...rest}
        >
          {tagContent}
        </BasicLink>
      );
    }

    return (
      <Text
        ref={tagRef as React.Ref<HTMLHeadingElement>}
        className={rootClasses}
        flavour={isDarkGreen ? 'meteo-dark-green' : textFlavour}
        tag="span"
        variant="tag"
      >
        {tagContent}
      </Text>
    );
  }
);

export { Tag };
